/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description, lang, meta, title,
  keywords, make, model, year,
  precio, sku, image, details, offer,
  path
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  )
  

    const schemaDefault={
      "@context": "http://www.schema.org",
      "@type": "AutoDealer",
      "name": "GRUPO RIVERO - Agencia Chevrolet Monterrey. Autos Seminuevos Monterrey. Autos Nuevos",
      "url": "www.gruporivero.com",
      "logo": `${site.siteMetadata?.image}`,
      "image": `${site.siteMetadata?.image}`,
      "description": "GRUPO RIVERO - Agencia Chevrolet Monterrey - Autos Seminuevos, Autos Nuevos, Autos Chevrolet - Carros y Camionetas Seminuevos a credito - Aveo, Beat, Spark, Onix, Cavalier - Seminuevos agencia -  Chevrolet Linda Vista - Chevrolet Guadalupe - Chevrolet Santa Catarina - Chevrolet San Pedro - Chevrolet Humberto Lobo - Chevrolet Gomez Morin - Chevrolet Vasconcelos - Taller de Servicio Chevrolet",
      "priceRange" : "$190,000 - $2,200,000",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Ave. Miguel Aleman 5400 Torres de Linda vista",
        "addressLocality": "Monterrey",
        "addressRegion": "Nuevo Leon",
        "postalCode": "67138",
        "addressCountry": "Mexico"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "25.66765470",
        "longitude": "-100.17471147"
      },
      "openingHours": "Mo, Tu, We, Th, Fr 09:00-20:00 Sa 09:00-18:30 Su 10:00-18:00",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+52-811-257-1257",
        "email": "informes@gruporivero.com",
        "contactType": "Ventas Agencia Chevrolet"
      } ,
      "telephone": "+52-811-257-1257"
    }

    const schemaDefault2={
      "@context": "http://www.schema.org",
      "@type": "Organization",
      "name": "GRUPO RIVERO - Agencia Chevrolet Monterrey. Autos Seminuevos Monterrey. Autos Nuevos",
      "url": "https://www.gruporivero.com",
      "logo": `${site.siteMetadata?.image}`,
      "image": `${site.siteMetadata?.image}`,
      "description": "GRUPO RIVERO - Agencia Chevrolet Monterrey - Autos Seminuevos, Autos Nuevos, Autos Chevrolet - Carros y Camionetas Seminuevos a credito - Aveo, Beat, Spark, Onix, Cavalier - Seminuevos agencia -  Chevrolet Linda Vista - Chevrolet Guadalupe - Chevrolet Santa Catarina - Chevrolet San Pedro - Chevrolet Humberto Lobo - Chevrolet Gomez Morin - Chevrolet Vasconcelos - Taller de Servicio Chevrolet",
      "sameAs" : [
        "https://www.facebook.com/agenciarivero",
        "https://twitter.com/agenciarivero",
        "https://www.linkedin.com/company/rivero-lindavista/",
        "https://www.youtube.com/gruporivero",
        "https://www.instagram.com/gruporivero/?hl=en",
       ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Ave. Miguel Aleman 5400 Torres de Linda vista",
        "addressLocality": "Monterrey",
        "addressRegion": "Nuevo Leon",
        "postalCode": "67138",
        "addressCountry": "Mexico"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+52-811-257-1257",
        "email": "informes@gruporivero.com",
        "contactType": "Ventas Chevrolet"
      }
    }
    const schemaDefault3={
      "@context": "http://www.schema.org",
      "@type": "WebSite",
      "name": "GRUPO RIVERO - Agencia Chevrolet Monterrey. Autos Seminuevos Monterrey. Autos Nuevos",
      "alternateName": "GRUPO RIVERO - Agencia Chevrlolet Monterrey - Autos Seminuevos, Autos Nuevos, Autos Chevrolet - Carros y Camionetas Seminuevos a credito - Aveo, Beat, Spark, Onix, Cavalier - Seminuevos agencia -  Chevrolet Linda Vista - Chevrolet Guadalupe - Chevrolet Santa Catarina - Chevrolet San Pedro - Chevrolet Humberto Lobo - Chevrolet Gomez Morin - Chevrolet Vasconcelos - Taller de Servicio Chevrolet",
      "url": "https://www.gruporivero.com"
  }
  var schemaOrgJSONLD={
      "@context": "http://www.schema.org",
      "@type": "product", 
      "brand": `${make?make:null}`,
      "name": `${model?model:null}`,
      "image": `${image?image:null}`,
      "description":`${model?(model+` `+year+`  - `+details):null}`,
      "offers": {
        "@type": "AggregateOffer",
        "lowPrice" : `${precio?precio:null}`,
        "highPrice" : `${precio?(precio+20000):null}`,
        "priceCurrency" : "Mexican Peso",
        "offerCount":`${offer?offer:null}`
      },
        "aggregateRating": {
          "@type": "aggregateRating",
          "ratingValue": "5",
          "reviewCount": "200",
          "bestRating":"5"
        },
        "review":{
          "@type": "Review",
          "name":"Excelente producto muy económico y potente",
          "author":"Enrique Casas"
        },
          "sku":`${sku?sku:null}`
    }
    

  const metaDescription = description
  const defaultTitle = site.siteMetadata?.title
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s` : ''}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name:`abstract`,
          content:metaDescription
        },
        {
          name:`google-site-verification`,
          content:`DUUyGpNQVx29hot2tTqtzuw0t4Ficyi7xif5t2oot4Y`
        },
        {
          name:`Language`,
          content:`Spanish`
        },
        {
          name:`copyright`,
          content:`Grupo Rivero Agencia Chevrolet Monterrey`
        },
        {
          name:`revisit-after`,
          content:`7 days`
        },
        {
          name:`robots`,
          content:`all`
        },
        {
          name:`robots`,
          content:`index,follow`
        },
        {
          name: `keywords`,
          content: keywords
        },
        {
          name:`canonical`,
          content:site.siteMetadata?.siteUrl || ``,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: site.siteMetadata?.image || ``,
        },
        {
          property: `og:url`,
          content: 
            (site?.siteMetadata?.siteUrl
              ? site?.siteMetadata?.siteUrl
              : ``)
            + (path ? path : ''),
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:image`,
          content: site.siteMetadata?.image || ``,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata?.siteUrl || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      >
        {title&&
        <script type="application/ld+json">{JSON.stringify(schemaDefault)}</script>
        }
         {title&&
        <script type="application/ld+json">{JSON.stringify(schemaDefault2)}</script>
        }
        {title&&
        <script type="application/ld+json">{JSON.stringify(schemaDefault3)}</script>
        }
        {model?
          <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
          :null
        }
        
         
      </Helmet>
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
